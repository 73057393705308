<template>
  <component :is="!planData ? 'div' : 'b-card'">
    <template v-if="planData">
      <div>
        <!--        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            &lt;!&ndash; Status &ndash;&gt;
            <b-row class="mt-1 " />
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-link
              class="alert-link"
              :to="{ name: 'apps-plans-list'}"
            >
              < Planes
            </b-link>
          </b-col>
        </b-row>-->
        <template>
          <!-- BODY -->
          <validation-observer
            #default="{invalid}"
            ref="refFormObserver"
          >
            <!-- User Info: Input Fields -->
            <b-form
              id="formEditClient"
              class="p-2"
              @submit.prevent="editPlanData(planData)"
              @reset.prevent="resetForm"
            >
              <!-- Header: Personal Info -->
              <div class="d-flex">
                <feather-icon
                  icon="LayersIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  {{ t('Planes.titleInformation') }}

                </h4>
              </div>
              <b-row class="mt-1 mb-2">
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- Status Plan -->
                  <validation-provider
                      #default="validationContext"
                      :name="$t('Planes.planList.planName')"
                      rules="required"
                  >
                  <b-form-group
                    :label="$t('message.tableHeader.status')"
                    label-for="plan-status"
                  >
                    <v-select
                      v-model="planData.status_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="planStatusOptions"
                      :value="planData.status_id"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="plan-status"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Name -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- Name -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Planes.planList.planName')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Planes.planList.planName')"
                      label-for="planName"
                    >
                      <b-form-input
                        id="first_name"
                        :maxlength="50"
                        v-model="planData.name"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        :placeholder="$t('Planes.planList.planNamePlaceholder')"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <!-- per_week -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- per_week -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Planes.planList.perWeek')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Planes.planList.perWeek')"
                      label-for="perWeek"
                    >
                      <v-select
                        v-model="planData.per_week"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perWeekOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="perWeek"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Discipline -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- Discipline -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Planes.planList.planName')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Planes.planList.disciplineName')"
                      label-for="disciplineName"
                      :state="getValidationState(validationContext)"
                      trim
                      :placeholder="$t('Planes.planList.disciplineName')"
                    >
                      <v-select
                        v-model="planData.discipline_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="planDisciplinesOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="disciplineName"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- month -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- month -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Planes.planList.months')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Planes.planList.months')"
                      label-for="months"
                    >
                      <v-select
                        v-model="planData.months"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="monthsOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="months"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- cost -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- cost -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Planes.planList.cost')"
                    rules="required|between:0,999999"
                  >
                    <b-form-group
                      :label="$t('Planes.planList.cost')"
                      label-for="cost"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon
                            size="16"
                            icon="DollarSignIcon"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          id="cost"
                          v-model="planData.cost"
                          :state="getValidationState(validationContext)"
                          trim
                          type="number"
                          step="0.01"
                          :placeholder="$t('Planes.planList.costPlaceholder')"
                        /></b-input-group>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <!-- discount -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <!-- discount -->
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Planes.planList.discount')"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Planes.planList.discount')"
                          label-for="discount"
                          label-class="mb-1"
                        >
                          <b-form-radio-group
                            id="discount"
                            v-model="planData.discount"
                            :options="planYesNoOptions"
                            :value="planData.discount?1:0"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      v-if="planData && planData.discount == 1"
                      cols="12"
                      md="6"
                    >
                      <!-- discount_percentage -->
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Planes.planList.discountPercentage')"
                      >
                        <b-form-group
                          :label="$t('Planes.planList.discountPercentage')"
                          label-for="discountPercentage"
                        >
                          <v-select
                            v-model="planData.discount_percentage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="discountOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="discountPercentage"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- accumulative -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Planes.planList.accumulative')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Planes.planList.accumulative')"
                      label-for="accumulative"
                      label-class="mb-1"
                    >
                      <b-form-radio-group
                        id="accumulative"
                        v-model="planData.accumulative"
                        :options="planYesNoOptions"
                        value=""
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- free_attendances -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Planes.planList.freeAttendance')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Planes.planList.freeAttendance')"
                      label-for="freeAttendance"
                      label-class="mb-1"
                    >
                      <b-form-radio-group
                        id="freeAttendance"
                        v-model="planData.free_attendance"
                        :options="planYesNoOptions"
                        value=""
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <hr>
              <!-- Buttons -->
              <b-row class="mt-2 mr-1">
                <b-col>
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    :disabled="invalid"
                  >
                    {{ t('Clients.buttons.save') }}
                    <feather-icon
                      v-if="loader"
                      icon="SettingsIcon"
                      size="15"
                      class="spinner"
                    />
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="resetUserData()"
                  >
                    {{ t('Clients.buttons.reset') }}
                  </b-button>
                </b-col>
                <b-link
                    :to="{ name: 'apps-plans-list'}"
                    class="font-weight-bold d-block text-nowrap"
                >
                  <feather-icon
                      icon="ArrowLeftIcon"
                      size="15"
                  />
                  Volver
                </b-link>
              </b-row>
            </b-form>
          </validation-observer>
        </template>
      </div>
    </template>
  </component>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormInvalidFeedback, BFormRadioGroup, BLink, BTooltip, BBadge, BAlert, BIcon, BInputGroupPrepend, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { alphaNum, email, required } from '@validations'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import planStoreModule from '@/views/Plans/planStoreModule'
import planDisciplineStoreModule from '@/views/Plans-disciplines/planDisciplineStoreModule'
import { useToast } from 'vue-toastification/composition'
import usePlansList from '@/views/Plans/plans-list/usePlansList'
import usePlansDisciplineList from '@/views/Plans-disciplines/plans-disciplines-list/usePlansDisciplinesList'
import { loader } from '@core/mixins/ui/globalMixin'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BIcon,
    BAlert,
    BBadge,
    BTooltip,
    BLink,
    BFormRadioGroup,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    flatPickr,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {},

  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  mixins: [loader],
  computed: {
    perWeekOptions() {
      const options = []
      for (let i = 1; i <= 7; i++) {
        options.push({ value: i, label: i.toString() })
      }
      return options
    },
    discountOptions() {
      const optionsDisc = []
      for (let j = 5; j <= 50; j += 5) {
        optionsDisc.push({ value: j, label: j.toString() })
      }
      return optionsDisc
    },
  },

  mounted() {
    this.getPlanData()
    this.getPlansDisciplines()
  },

  methods: {
    resetUserData() {
      this.getPlanData()
    },
  },

  create() {

  },
  setup(props, { emit }) {
    const blankPlanData = {
      name: '',
      per_week: '',
      discount: 0,
      accumulative: 0,
      free_attendance: 0,
      discount_percentage: '',
      cost: '',
      months: '',
      vf: '',
      discipline_id: '',
      status_id: '',
    }

    const PLAN_APP_STORE_MODULE_NAME = 'app-plan'
    const PLAN_DISCIPLINE_APP_STORE_MODULE_NAME = 'app-plan-discipline'

    // Register module
    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    if (!store.hasModule(PLAN_DISCIPLINE_APP_STORE_MODULE_NAME)) store.registerModule(PLAN_DISCIPLINE_APP_STORE_MODULE_NAME, planDisciplineStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PLAN_APP_STORE_MODULE_NAME)) store.unregisterModule(PLAN_APP_STORE_MODULE_NAME)
      if (store.hasModule(PLAN_DISCIPLINE_APP_STORE_MODULE_NAME)) store.unregisterModule(PLAN_DISCIPLINE_APP_STORE_MODULE_NAME)
    })

    // const planData = ref(JSON.parse(JSON.stringify(blankPlanData)))
    const resetuserData = () => {
      // planData.value = JSON.parse(JSON.stringify(blankPlanData))
    }
    const toast = useToast()
    const {
      t,
      getPlanData,
      planData,
      editPlanData,
      planStatusOptions,
      planYesNoOptions,
      monthsOptions,
    } = usePlansList()

    const {
      getPlansDisciplines,
      planDisciplinesOptions,

    } = usePlansDisciplineList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      planData,
      getPlanData,
      editPlanData,
      getPlansDisciplines,
      planStatusOptions,
      planDisciplinesOptions,
      monthsOptions,
      toast,
      refFormObserver,
      getValidationState,
      planYesNoOptions,
      resetForm,
      emit,
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
