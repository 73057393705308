import useJwt from '../../auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPlansDiscipline(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .post('/plan/discipline/search', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPlanDiscipline(ctx, { id }) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .get(`/plan/discipline/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPlanDiscipline(ctx, disciplineData) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .post('/plan/discipline', { disciplineData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
