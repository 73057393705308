import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import { dateLatin, title } from '@core/utils/filter'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import moment from 'moment'

export default function usePlansDisciplineList() {
  const toast = useToast()
  const { t } = useI18nUtils()

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: t('Planes.planList.planName'),
      sortable: true,
    },
    {
      key: 'discount',
      label: t('Planes.planList.discount'),
      // formatter: title,
      sortable: true,
    },
    {
      key: 'discountPercentage',
      label: t('Planes.planList.discountPercentage'),
      // formatter: title,
      sortable: false,
    },
    {
      key: 'cost',
      label: t('Planes.planList.cost'),
      // formatter: title,
      sortable: true,
    },
    {
      key: 'months',
      label: t('Planes.planList.months'),
      // formatter: title,
      sortable: true,
    },
    {
      key: 'discipline',
      label: t('Planes.planList.disciplineName'),
      formatter: title,
      sortable: true,
    },
    {
      key: 'created',
      label: t('Planes.planList.createdDate'),
      // formatter: dateLatin,
      sortable: true,
    },
    {
      key: 'status',
      label: t('Planes.planList.status'),
      formatter: title,
      sortable: true,
    },
    {
      key: 'actions',
      label: t('Planes.planList.actions'),
      sortable: false,
    },
  ]

  const refPlanListTable = ref(null)
  const planDisciplinesOptions = ref([0])
  const planDisciplineData = ref(null)
  const listPlans = ref(null)
  const totalRootClient = ref(0)
  const perPage = ref(10)
  const totalPlansDiscipline = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPlanListTable.value ? refPlanListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPlansDiscipline.value,
    }
  })

  const refetchDataPlans = () => {
    refPlanListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter], () => {
    refetchDataPlans()
  })

  const fetchPlansDiscipline = (ctx, callback) => {
    if (searchQuery.value.length == 0 || searchQuery.value.length >= 3) {
      store
        .dispatch('app-plan-discipline/fetchPlansDiscipline', {
          query: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          // clientStatus: statusFilter.value,
        })
        .then(response => {
          const { planDiscipline, total } = response.data
          callback(planDiscipline)
          planDisciplineData.value = planDiscipline
          totalPlansDiscipline.value = total
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error al listar las disciplinas de los planes',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      callback(refPlanListTable.value.localItems)
    }
  }

  const getPlansDisciplines = () => store.dispatch('app-plan-discipline/fetchPlansDiscipline')
    .then(response => {
      const { plans } = response.data
      const op = []
      plans.map(plan => {
        op.push({
          label: plan.name,
          value: plan.id,
          name: plan.name,
        })
      })
      planDisciplinesOptions.value = op
    })
    .catch(error => {
      console.log(error)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error al listar los planes',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const getDate = () => moment().startOf('day')

  return {
    fetchPlansDiscipline,
    getPlansDisciplines,
    planDisciplineData,
    getDate,
    listPlans,
    totalRootClient,
    planDisciplinesOptions,
    tableColumns,
    perPage,
    currentPage,
    totalPlansDiscipline,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPlanListTable,
    refetchDataPlans,

    // Extra Filters
    t,
  }
}
